import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ButtonSolid from "../components/Button/ButtonSolid";
import ReviewCards from "../components/Reviews/ReviewCards";
import CallToAction from "../components/Repeating/CTA";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="Reviews | Home Loans | Accel Mortgage"
        description="Accel Mortgage are a mortgage broker in Pasadena, CA. Read our reviews to see how we provide our clients with exceptional service."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="bg-primary-50 pt-10 md:pt-12">
        <div className="container">
          <header className="mx-auto mb-16 max-w-[640px] text-center md:mb-20">
            <h1>What Our Clients Say</h1>
            <p>
              This is why we do what we do—hear what happy homeowners say about
              their Accel Mortgage loan experience.
            </p>

            <ButtonSolid href="/review-us/" text="Leave a Review" />
          </header>

          <div className="mb-16 md:mb-22 md:columns-2 md:gap-x-12">
            <ReviewCards />
          </div>
        </div>
      </section>

      <CallToAction />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
